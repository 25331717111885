import React from 'react';
import { createRoot } from 'react-dom/client';

const Realms = React.lazy(() => import('src/realms'));

// LOAD SCSS.
import 'src/scss/body.scss';
import 'src/scss/fonts.scss';
import 'src/scss/text.scss';
import 'src/scss/forms.scss';
import 'src/scss/visibility.scss';
import 'src/scss/buttons.scss';
import 'src/scss/animations.scss';

const Start = React.memo((props) => {
    return (
        <React.Suspense fallback={null}>
            <Realms />
        </React.Suspense>
    );
});

// REDIRECT TO HTTPS IF HTTP.
if (['http:','http'].includes(window.location.protocol)) window.location.href = `https://www.hypism.com` + window.location.pathname;

// UPDATED TO REACT 18.
document.addEventListener('DOMContentLoaded', (e) => {
    // START BUILDING PAGE.
    const container = document.getElementById('body');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Start />
        </React.StrictMode>
    );
});